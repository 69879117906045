import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const DelayedNavigate = ({ to, delay }) => {
    const navigate = useNavigate;

    useEffect(() => {
        const timer = setTimeout(() => {
            navigate(to);
        }, delay);

        return () => clearTimeout(timer);
    }, [navigate, to, delay]);

  return null;
}

export default DelayedNavigate