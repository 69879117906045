import { useState, useEffect, lazy, Suspense, useContext } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import { useTranslation, withTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import { Helmet } from 'react-helmet';
import { GlobalCtx } from './context/GlobalContext';
import HomeCtx from './context/HomeContext';
import PetitionCtx from './context/PetitionContext';
import TopicsCtx from './context/TopicsContext';
import VictoriesCtx from './context/VictoriesContext';
import ShareCtx from './context/ShareContext';
import DelayedNavigate from './utils/DelayedNavigate';
const Loader = lazy(() => import('./components/Loader'));
const HomePage = lazy(() => import('./pages/HomePage/HomePage'));
const CookiesPopup = lazy(() => import('./components/CookiesPopup'));
const Victories = lazy(() => import('./pages/Victories/Victories'));
const Topics = lazy(() => import('./pages/Topics/Topics'));
const Petition = lazy(() => import('./pages/Petition/Petition'));
const NotFoundPage = lazy(() => import('./pages/NotFoundPage'));
const SharePage = lazy(() => import('./pages/Share/SharePage'));
const Unsubscribe = lazy(() => import('./pages/Unsubscribe/Unsubscribe'));
// const AskForDonation = lazy(() => import('./pages/Share/AskForDonation'));

const App = () => {
  const [petitionsNumber, setPetitionsNumber] = useState(6);
  const [cookieAccepted, setCookieAccepted] = useState(true);

  const { t } = useTranslation();
  const { lang } = useContext(GlobalCtx);

  const installFbPixel = () => {
    return (
      <Helmet>
        <script>
          {`!function(f,b,e,v,n,t,s)
  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)}(window, document,'script',
  'https://connect.facebook.net/en_US/fbevents.js');
  fbq('init', '886216829949487');
  fbq('track', 'PageView');`}
        </script>
        <noscript>{`<img height="1" width="1" style="display:none"
  src="https://www.facebook.com/tr?id=886216829949487&ev=PageView&noscript=1"
  />`}</noscript>
      </Helmet>
    );
  };

  // Increase petitions number (show 3 more petitions)
  const incresePetitions = () => {
    setPetitionsNumber(petitionsNumber + 3);
  };

  const notEEAContries = [
    'en-au',
    'en-ca',
    'en-us',
    'es-ar',
    'es-lat',
    'es-mx',
    'pt-br',
  ];

  // Change favicon and title
  useEffect(() => {
    const favicon = document.querySelector("link[rel~='icon']");
    if (lang === 'hazteoir') {
      favicon.href = 'ho_favicon.ico';
      document.title = 'Hazte Oír';
    } else {
      favicon.href = 'favicon.ico';
    }
  }, []);

  // Check if the cookie is accepted. If not show the cookies popup
  useEffect(() => {
    if (
      Cookies.get('_accepted_cookie') !== undefined ||
      notEEAContries.includes(lang)
    ) {
      setCookieAccepted(true);
    } else {
      setCookieAccepted(true);
    }
  }, []);

  return (
    <>
      {installFbPixel()}
      {/* Create router with basename on props.i18n.lang. Basename is used also for 404 page */}
      {/* i18next configuration is done before the App.js component and injects /{lang} into the url. Adding a condition allows you to capture the moment of entering the address without parameters but with any parameters after /. lang and display 404 */}
      <Router
        basename={
          !window.location.pathname.startsWith(`/${lang}`)
            ? ''
            : lang || window.location.pathname
        }
      >
        {cookieAccepted ? null : (
          <CookiesPopup
            cookies_blocker={
              !navigator.cookieEnabled ? t('cookies_popup.alert') : ''
            }
          />
        )}
        {/* <Header i18n={i18n || window.location.pathname} /> */}
        <Loader />
        <Routes>
          <Route
            // If the path after basename is exactly empty...
            exact
            path="/"
            // ...display the home page component
            element={
              <HomeCtx>
                <HomePage
                  incresePetitions={incresePetitions}
                  petitionsNumber={petitionsNumber}
                />
              </HomeCtx>
            }
          />
          {/* render component defines in element and search by defined path */}
          <Route
            path="/victories"
            element={
              <Suspense fallback={<Loader />}>
                <VictoriesCtx>
                  <Victories />
                </VictoriesCtx>
              </Suspense>
            }
          />
          <Route path="/temp-donate" />
          <Route path="/temp-join" />
          <Route
            path="/topics/:topic"
            element={
              <Suspense fallback={<Loader />}>
                <TopicsCtx>
                  <Topics />
                </TopicsCtx>
              </Suspense>
            }
          />
          <Route
            path="/LP2-share"
            element={
              <Suspense fallback={<Loader />}>
                <ShareCtx>
                  <SharePage />
                </ShareCtx>
              </Suspense>
            }
          />
          <Route
            path="/:topic/:nid"
            element={
              <Suspense fallback={<Loader />}>
                <PetitionCtx>
                  <Petition />
                </PetitionCtx>
              </Suspense>
            }
          />
          <Route
            path="/*"
            element={
              <Suspense fallback={<Loader />}>
                <DelayedNavigate to="/notfound" delay={1000} />
              </Suspense>
            }
          />
          <Route
            path="notfound"
            element={
              <Suspense fallback={<Loader />}>
                <NotFoundPage replace />
              </Suspense>
            }
          />
          <Route
            path="/unsubscription"
            element={
              <Suspense fallback={<Loader />}>
                <Unsubscribe />
              </Suspense>
            }
          />
        </Routes>
      </Router>
    </>
  );
};

export default withTranslation()(App);
